
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDEToolbarItem',

  components: {
    PDEIcon,
  },

  props: {
    icon: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    placement: {
      type: String,
      default: 'bottomRight',
      validator: value => ['bottomLeft', 'bottomRight'].indexOf(value) !== -1,
    },
  },
}
