
import kebabCase from 'lodash.kebabcase'
import getReadabilityClass from '@/mixins/getReadabilityClass/getReadabilityClass.js'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEDropDownItem from '@/components/pde/PDEDropDownItem.vue'

export default {
  name: 'PDEDropDown',

  components: {
    PDEIcon,
    PDEButton,
    PDEDropDownItem,
  },

  mixins: [getReadabilityClass],

  props: {
    name: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      default: () => [],
    },

    placement: {
      type: String,
      default: 'bottomLeft',
      validator: value => ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'].indexOf(value) !== -1,
    },

    noWrap: {
      type: Boolean,
      default: false,
    },

    analyticsData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggled'],

  data() {
    return {
      showDropdown: false,
    }
  },

  computed: {
    placementClass() {
      const placements = {
        bottomLeft: 'left-0 top-0',
        bottomRight: 'right-0 top-0',
        topLeft: 'left-0 bottom-0',
        topRight: 'right-0 bottom-0',
      }

      return placements[this.placement]
    },

    anchorClass() {
      const placements = {
        bottomLeft: 'bottom-0',
        bottomRight: 'bottom-0',
        topLeft: 'top-0',
        topRight: 'top-0',
      }

      return placements[this.placement]
    },

    showDropdownClass() {
      return this.showDropdown ? 'hover:block hover:rounded-3' : 'hidden'
    },

    textWrapClass() {
      return this.noWrap && 'whitespace-nowrap'
    },
  },

  methods: {
    kebabCase,

    handleClick(func) {
      if (func) {
        func()
      }
    },

    emitToggleEvent() {
      this.$emit('toggled', this.showDropdown)
    },

    hide($event) {
      if (this.showDropdown) {
        this.showDropdown = false
        this.emitToggleEvent()
      }
    },

    toggle() {
      this.showDropdown = !this.showDropdown
      this.emitToggleEvent()
    },
  },
}
