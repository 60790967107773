import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aa-click-text", "aa-click-name", "aa-click-area"]
const _hoisted_2 = {
  key: 1,
  class: "pdedropdown-item text-14 p-16 no-underline flex items-center w-full rounded-3 duration-500 bg-pureblack-5 hover:text-black-500 text-black-500 cursor-not-allowed"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$props.disabled)
    ? (_openBlock(), _createElementBlock("a", _mergeProps({
        key: 0,
        class: "pdedropdown-item text-14 p-16 no-underline flex items-center w-full rounded-3 duration-500 bg-pureblack-5 hover:bg-primary-50 hover:text-black-900 active:bg-primary-100 cursor-pointer text-black-800"
      }, _ctx.$attrs, {
        "aa-click-text": $props.analyticsData && $props.analyticsData.clickText,
        "aa-click-name": $props.analyticsData && $props.analyticsData.clickName,
        "aa-click-area": $props.analyticsData && $props.analyticsData.clickArea,
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('click')))
      }), [
        _renderSlot(_ctx.$slots, "default")
      ], 16, _hoisted_1))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}