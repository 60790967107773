
import { mapActions, mapGetters, mapState } from 'vuex'
import PDEToolbarItem from '@/components/pde/PDEToolbarItem.vue'
import PDEBadge from '@/components/pde/PDEBadge.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEDropDown from '@/components/pde/PDEDropDown.vue'
import PDEDropDownItem from '@/components/pde/PDEDropDownItem.vue'
import { AvailableToWorkToggle } from '@/components/account/Settings'
import getReadabilityClass from '@/mixins/getReadabilityClass/getReadabilityClass.js'

export default {
  name: 'ToolbarItemProfile',
  components: {
    PDEToolbarItem,
    PDEBadge,
    PDEIcon,
    PDEDropDown,
    PDEDropDownItem,
    AvailableToWorkToggle,
  },

  mixins: [getReadabilityClass],

  data() {
    return {
      dropdownOpen: false,
    }
  },

  computed: {
    ...mapGetters('account', ['availableToWork', 'division', 'getFormalName', 'isFullUser', 'workdayEnabled', 'isPartialUser']),
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapState('account', ['isAlliedUser']),

    showManageWorkday() {
      return this.workdayEnabled && this.isAlliedUser && this.isFullUser
    },

  },

  methods: {
    ...mapActions('navigation', [
      'signoutRedirect',
      'navigateToAccountSettings',
      'navigateToWorkPreferences',
      'navigateToHelp',
      'redirectToWorkday',
    ]),

  },
}
